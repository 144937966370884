import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import store, { persistor } from './redux/store';
import * as serviceWorker from './serviceWorker';
import { TsaAppContext, DEFAULT_CONTEXT_VALUE } from './tsaAppContext';
import '@styles/index.scss';
import App from './layout/Entry';

const { ThemeContainer } = require('appkit-react');

window.onload = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <TsaAppContext.Provider value={DEFAULT_CONTEXT_VALUE}>
            <ThemeContainer theme="theme-light">
              <App />
            </ThemeContainer>
          </TsaAppContext.Provider>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
