import React, { useEffect, memo, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import '@styles/pages/register.scss';
import { Logo } from '@components';
import { Form, Input, Button } from 'antd';
import { register, getUserInfo } from '@services';
import { RootStoreType } from '@reducer';
import { loginAction } from '@action';
import { Alert } from '../../services/axios.service';
const { Tooltip } = require('appkit-react');

const mapStoreToProps = (store: RootStoreType) => {
  const { login } = store;
  return {
    userInfo: login.userInfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  login: (type: string) => loginAction.login(type),
  saveUserInfo: (user: any) => loginAction.saveUserInfo(user),
});

const connector = connect(mapStoreToProps, mapDispatchToProps);

interface RegisterPropsInterface
  extends RouteComponentProps,
    ConnectedProps<typeof connector> {}

function Register(props: RegisterPropsInterface) {
  const dispatch = useDispatch();
  const { location } = props;
  const [tokens, setTokens] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (location.search) {
      const { search } = location;
      const urSearch = search && search.match(/\?([\s\S]*)$/);
      const tokens: any = {};
      if (urSearch) {
        urSearch[1]
          .split('&')
          .map((item: any) => item.split('='))
          .forEach((arr: any) => {
            tokens[arr[0]] = arr[1];
          });
      }
      tokens.email = decodeURIComponent(tokens.email);
      form.setFieldsValue({ email: tokens.email });
      setTokens(tokens);

      window.sessionStorage.tokens = JSON.stringify(tokens);
    }
  }, [location, form]);

  const onFinish = async (values: any) => {
    setLoading(true);
    const { password, email, firstName, lastName } = values;
    const { registrationToken, orgId } = tokens;

    const v = {
      body: {
        password,
        email,
        registrationToken,
        firstName,
        lastName,
        orgId,
      },
    };

    const res = await register(v.body);
    console.log('res: ', res);

    // If res has data.token, it is registered successfully
    if (res && res.token) {
      res && window.localStorage.setItem('accessToken', res.token);
      const user = await getUserInfo();
      if (user && user.uid) {
        dispatch(loginAction.saveUserInfo(user));
        window.localStorage.setItem('userInfo', JSON.stringify(user));
        //       await asyncTsaClientInfo(
        //         {
        //           userEmail: user.email || '',
        //           userName: user.name || user.fullName,
        //         },
        //         res.token,
        //       );
        props.history.push('/login');
      }
    } else {
      Alert(res?.description || 'error');
    }

    setLoading(false);
  };

  const onFinishFailed = (_errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  const handleSignIn = () => {
    window.sessionStorage.setItem('loginProgress', 'logged');
    props.login && props.login('iam');
  };

  return (
    <div className="register-page-wrapper">
      <div className="register-container">
        <Logo />

        <div className="register-page">
          <div className="text">Register</div>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            name="register"
            autoComplete="off"
          >
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: 'Please input your first name!' },
                {
                  pattern: /^[0-9a-zA-Z]+$/,
                  message: 'No special characters in name.',
                },
                { max: 30, message: 'First Name cannot exceed 30 characters.' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: 'Please input your last name!' },
                {
                  pattern: /^[0-9a-zA-Z]+$/,
                  message: 'can not have special charactor',
                },
                { max: 30, message: 'Last Name cannot exceed 30 characters.' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label={
                <div className="form-item-password">
                  <div className="left">Password</div>
                  <div className="right">
                    {' '}
                    <Tooltip
                      content={[
                        <div className="password-tooltip" key={0}>
                          <strong>Password must contain</strong>
                          <br />
                        </div>,
                        <div className="password-tooltip" key={1}>
                          {' '}
                          1. At least 8 letters, maximum 20 letters
                          <br />
                          2. At least one lower case letter
                          <br />
                          3. At least one upper case letter
                          <br />
                          4. At least one number
                          <br />
                          5. At least one special character
                        </div>,
                      ]}
                      placement="top"
                      tooltipTheme="dark"
                      className="auth-tooltip"
                    >
                      <span className="icon-information-fill appkiticon" />
                    </Tooltip>
                  </div>
                </div>
              }
              name="password"
              rules={[
                { required: true, message: 'Please input your password!' },
                {
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,20}$/,
                  // pattern:
                  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/,
                  message:
                    'The password that you entered does not meet criteria!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      'The two passwords that you entered do not match!',
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                className="btn-wrapper"
                htmlType="submit"
                type="primary"
                loading={loading}
              >
                Sign Up
              </Button>
            </Form.Item>
            <div className="footer-login">
              Already have an account?{' '}
              <Button type="link" className="signin-btn" onClick={handleSignIn}>
                Sign In
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
export default connector(memo(Register));
