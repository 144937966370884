import React, { useEffect, memo, useState, useCallback, useRef } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import '@styles/pages/register.scss';
import { RootStoreType } from '@reducer';
import { loginAction } from '@action';
import { clearLocalStorage, getFirstPathname, isInvalidUserInfo } from '@utils';
// import { Redirect } from 'react-router-dom';
import { Button } from 'antd';

import '@styles/pages/landingpage.scss';
import RequestAccessModal from '@src/components/RequestAccessModal';
import {
  getAllProjectCount,
  getUserInfo,
  getAcceptTermsRequest,
} from '@services';
import useRequestAccessService from '@src/hooks/useRequestAccessService';
import Loading from '@components/Loading';
import TermsModal from '@components/TermsModal';

const mapStoreToProps = (store: RootStoreType) => {
  const { login } = store;
  return {
    userInfo: login.userInfo,
    isLogin: login.isLogin,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  login: (type: string) => dispatch(loginAction.login(type)),
  logout: loginAction.logout(),
});

const connector = connect(mapStoreToProps, mapDispatchToProps);

interface ILandingPage
  extends RouteComponentProps,
    ConnectedProps<typeof connector> {}
interface IModuleType {
  name: string;
  url: string | '' | undefined;
  permissionKey: string[];
  projectCountKey: string;
  getProjectDetailURL: (projectId: string) => string;
  getProjectListURL: () => string;
}

// 1. validate user info and check login
// 2. check 'from' from session storage => check tool access permission => to error page or to previous url
// 3. only have one project => check tool access permission => to error page or specific tool
// 4. have multiple project => stay landing page to decide

const componentData: IModuleType[] = [
  {
    name: 'Gateway',
    url: process.env?.REACT_APP_DD_URL,
    permissionKey: ['SystemAccess.Gateway', 'SystemAccess.Gateway.Client'],
    projectCountKey: 'gateway',
    getProjectDetailURL(projectId) {
      return `${this.url}project-detail/${projectId}`;
    },
    getProjectListURL() {
      return `${this.url}projects/list`;
    },
  },
  {
    name: 'TSA',
    url: process.env?.REACT_APP_TSA_URL,
    permissionKey: ['SystemAccess.TSA', 'SystemAccess.TSA.Client'],
    projectCountKey: 'tsa',
    getProjectDetailURL(projectId) {
      return `${this.url}overview/${projectId}`;
    },
    getProjectListURL() {
      return `${this.url}project/list`;
    },
  },
  // {
  //   name: 'Standalone',
  //   url: process.env?.REACT_APP_SA_URL,
  //   permissionKey: ['SystemAccess.DBM', 'SystemAccess.DBM.Client'],
  //   projectCountKey: 'sa',
  //   getProjectDetailURL(projectId){
  //     return this.url + `project-detail/${projectId}`
  //   },
  //   getProjectListURL(){
  //     return this.url + `projects/list`
  //   }
  // },
];

function LandingPage(props: ILandingPage) {
  const { location, userInfo, logout, isLogin, history } = props;
  // const { permissions } = userInfo;
  const fetchedUserRef = useRef(false);
  const [projectCountLoading, setProjectCountLoading] = useState(false);
  const [projectCount, setProjectCount] = useState<{ [x: string]: string[] }>(
    {},
  );
  const [userInfoLoading, setUserInfoLoading] = useState<boolean>(true);
  const [termsModalVisible, setTermsModalVisible] = useState<boolean>(false);
  const [termsLoading, setTermsLoading] = useState<boolean>(false);
  const [redirectLoading, setRedirectLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.search) {
      const { search } = location;
      const urSearch = search && search.match(/\?([\s\S]*)$/);
      const tokens: any = {};
      if (urSearch) {
        urSearch[1]
          .split('&')
          .map((item: any) => item.split('='))
          .forEach((arr: any) => {
            tokens[arr[0]] = arr[1];
          });
      }
    }
  }, [location]);

  useEffect(() => {
    if (fetchedUserRef.current && userInfo && userInfo.permissions && userInfo.permissions.length) {
      const accepted = window.localStorage.getItem('accepted');
      if (accepted === "true") {
        init();
      } else {
        setTermsLoading(true);
        getAcceptTermsRequest(userInfo.uid || userInfo.email)
          .then((res: any) => {
            if (String(res) !== 'true') {
              setTermsModalVisible(true);
            } else {
              window.localStorage && window.localStorage.setItem('accepted', 'true');
              init();
            }
          })
          .finally(() => setTermsLoading(false));
      }
    } else {
      fetchedUserRef.current && init();
    }
  }, [userInfo])

  useEffect(() => {
    updateUserInfo();
  }, []);

  async function updateUserInfo() {
    const loginValid = validateUserInfo() && isLogin;
    if (!loginValid) {
      fetchedUserRef.current = true;
      setUserInfoLoading(false);
      return;
    }
    setUserInfoLoading(true);
    let user = null;
    try {
      user = await getUserInfo();
      if (user && user.uid) {
        fetchedUserRef.current = true
        dispatch(loginAction.saveUserInfo(user));
        window.localStorage.setItem('userInfo', JSON.stringify(user));
      }
    } catch (e) {
      console.log(e);
    }
    setUserInfoLoading(false);
  }

  async function init() {
    let from = window.localStorage.getItem('from');
    from = from && decodeURIComponent(from);
    if (from) {
      redirect(from);
      window.localStorage.removeItem('from');
    } else {
      await fetchAllProjectCount();
    }
  }

  function validateUserInfo() {
    if (isInvalidUserInfo(userInfo)) {
      clearLocalStorage();
      logout(dispatch).finally(() => {
        history.push('/login');
      });
      return false;
    }
    return true;
  }

  function checkModuleAccessPermission(url: string) {
    if (!url) {
      return false;
    }
    const targetPathname = getFirstPathname(url);
    const targetModule = componentData.find((module) => {
      const modulePath = getFirstPathname(module.url || '');
      return modulePath === targetPathname;
    });
    const valid =
      targetModule &&
      userInfo?.permissions?.some((key) =>
        targetModule.permissionKey.includes(key),
      );
    if (!valid && targetModule) {
      history.replace(`/error?requestApp=${targetModule.name}`);
    }

    return valid;
  }

  const redirect = (url: string = '') => {
    setRedirectLoading(true);
    if (checkModuleAccessPermission(url)) {
      window.location.href = url;
    } else {
      setRedirectLoading(false);
    }
  };

  const {
    allowedApps = [],
    pendingApps,
    fetchPermissions,
    loading: permissionLoading,
  } = useRequestAccessService();

  const fetchAllProjectCount = useCallback(async () => {
    setProjectCountLoading(true);
    try {
      const res: { [key: string]: string[] } = await getAllProjectCount();
      for (const key in res) {
        if (res[key].length) {
          res[key] = res[key].filter((i) => Boolean(i));
        }
      }
      setProjectCount(res);
      const allProjectCount = Object.values(res).reduce(
        (previous, current) => previous + current?.length,
        0,
      );
      const validToolCount = Object.values(res).filter(
        (i) => i.length > 0,
      ).length;

      // const gatewayModule = componentData[0];
      // if(userInfo.isInternal && res[gatewayModule.projectCountKey]?.length) {
      //   return redirect(gatewayModule.getProjectListURL())
      // }
      console.log('res: ', JSON.stringify(res));
      console.log('allProjectCount: ', allProjectCount);
      console.log('validToolCount: ', validToolCount);
      console.log('allowedApps: ', allowedApps);
      // 拥有GW的权限
      const hasGWPermission =
        (allowedApps || []).includes('SystemAccess.Gateway') ||
        (allowedApps || []).includes('SystemAccess.Gateway.Client');
      // 拥有TSA的权限
      const hasTSAPermission =
        (allowedApps || []).includes('SystemAccess.TSA') ||
        (allowedApps || []).includes('SystemAccess.TSA.Client');

      // 如果都没有权限则停留在当前页面
      if (!hasGWPermission && !hasTSAPermission) {
        return setProjectCountLoading(false);
      }

      const gatewayModuleDefine = componentData[0];
      const tsaModuleDefine = componentData[1];

      // 当前用户在GW拥有的项目数量
      const gatewayProjectIds = res[gatewayModuleDefine.projectCountKey];
      // 当前用户在TSA拥有的项目数量
      const tsaProjectIds = res[tsaModuleDefine.projectCountKey];

      // check gateway project first
      if (gatewayProjectIds?.length && hasGWPermission) {
        const onlyHasOneProject = gatewayProjectIds.length === 1;
        redirect(
          onlyHasOneProject && gatewayProjectIds[0]
            ? gatewayModuleDefine.getProjectDetailURL(gatewayProjectIds[0])
            : gatewayModuleDefine.getProjectListURL(),
        );
      } else if (tsaProjectIds?.length && hasTSAPermission) {
        // 有tsa项目并且有tsa权限才走到这里
        const onlyHasOneProject = tsaProjectIds.length === 1;
        redirect(
          onlyHasOneProject && tsaProjectIds[0]
            ? tsaModuleDefine.getProjectDetailURL(tsaProjectIds[0])
            : tsaModuleDefine.getProjectListURL(),
        );
      } else if (hasGWPermission) {
        redirect(gatewayModuleDefine.getProjectListURL());
      } else if (hasTSAPermission) {
        redirect(tsaModuleDefine.getProjectListURL());
      } else {
        // 当GW和TSA都没项目的时候跳到GW的list页面
        redirect(gatewayModuleDefine.getProjectListURL());
      }
      setProjectCountLoading(false);
    } catch (e) {
      console.log(e);
      setProjectCountLoading(false);
    }
  }, []);

  const [requestApp, setRequestApp] = useState<string | null>(null);

  const afterModalOK = () => {
    setRequestApp(null);
    fetchPermissions();
  };

  const handleTermsAccept = () => {
    setTermsModalVisible(false);
    init();
  };

  const loading =
    projectCountLoading ||
    permissionLoading ||
    userInfoLoading ||
    termsLoading ||
    redirectLoading ||
    allowedApps.length > 0;

  return (
    <>
      <RequestAccessModal
        userInfo={userInfo}
        requestApp={requestApp}
        AfterClose={afterModalOK}
      />
      {loading ? (
        <Loading />
      ) : (
        <div className="landing-page-wrapper">
          <div className="title-area">
            <p>Select a Module to begin</p>
            <i>You will be able to navigate to other modules</i>
          </div>
          <div className="button-area">
            {componentData.map((data: IModuleType) => {
              const hasPermission = (allowedApps || []).some((key) =>
                data.permissionKey.includes(key),
              );
              const permissionToGrant = (pendingApps || []).some(
                (p) => data.name === p,
              );
              return (
                <div
                  key={data.name}
                  className={hasPermission ? '' : 'disabled'}
                  onClick={() => {
                    if (hasPermission) {
                      if (projectCount[data.projectCountKey]?.length === 1) {
                        redirect(
                          data.getProjectDetailURL(
                            projectCount[data.projectCountKey][0],
                          ),
                        );
                      } else {
                        redirect(data.url);
                      }
                    }
                  }}
                >
                  <div className="app-name">{data.name}</div>
                  {userInfo?.isInternal ? (
                    <Button
                      disabled={!hasPermission && permissionToGrant}
                      className="request-access-btn"
                      onClick={() => setRequestApp(data.name)}
                    >
                      {!hasPermission && permissionToGrant
                        ? 'Requested'
                        : 'Request Access'}
                    </Button>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      )}

      <TermsModal
        onAccept={handleTermsAccept}
        visible={termsModalVisible}
        userInfo={userInfo}
      />
    </>
  );
}
export default connector(memo(LandingPage));
